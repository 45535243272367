import Layout from '../pages/layout';
import RouteView from '../pages/layout/RouteView';
import advertiserManage from './advertiserManage';
import materialManage from './materialManage';
import deliverManages from './deliverManages';
import JLAdvertManage from './JLAdvertManage';
import releaseEffectReport from './releaseEffectReport';
import XHSAgencyRouter from './XHSAgencyRouter';
import aigc from './aigc';

/**
 * 应用
 */
export default {
  path: '/application',
  name: 'application',
  component: Layout,
  meta: { title: '应用', icon: 'appstore' },
  children: [
    // 小红书代运营
    { ...XHSAgencyRouter },
    // AIGC
    { ...aigc },
    // 数据分析
    {
      path: 'dataAnalyse',
      name: 'dataAnalyse',
      component: RouteView,
      meta: { title: '数据分析', icon: 'file-sync' },
      children: [
        {
          path: 'rule',
          name: 'dataAnalyse:rule',
          component: () => import('@/pages/app/dataAnalyse/ruleTable.vue'),
          meta: { title: '自助取数规则' },
        },
        {
          path: 'exportResult',
          name: 'dataAnalyse:group',
          component: () => import('@/pages/app/dataAnalyse/exportResult.vue'),
          meta: { title: '导出结果' },
        },
        {
          path: 'create',
          name: 'dataAnalyse:create',
          component: () => import('@/pages/app/dataAnalyse/create.vue'),
          meta: { title: '新建取数规则', noPadding: true },
        },
      ],
    },
    // 皮皮慧投
    {
      path: 'pphtManage',
      name: 'pphtManage',
      component: RouteView,
      meta: { title: '皮皮慧投', icon: 'file-sync' },
      children: [
        // 慧集客
        { ...advertiserManage },
        // 线索管理
        {
          path: '/clueManages',
          name: 'clueManages:clueIndex',
          component: RouteView,
          meta: { title: '线索管理', icon: 'database' },
          redirect: '/clueManage',
          children: [
            //线索管理
            {
              path: '/clueManage',
              name: 'clueManage:clueIndex',
              component: () => import('@/pages/app/clueManage/index'),
              meta: { title: ' 全部线索' },
            },
            //线索管理-线索明细
            {
              path: '/clueManage/detail',
              name: 'clueManage:clueDetail',
              component: () => import('@/pages/app/clueManage/detail'),
              hidden: true,
              meta: { title: '线索明细' },
            },
            //线索管理-修改车系
            {
              path: '/clueManage/carSeries',
              name: 'clueManage:clueCarSeries',
              component: () => import('@/pages/app/clueManage/carSeries'),
              hidden: true,
              meta: { title: '修改车系' },
            },
          ],
        },
        // 案例内容统计
        {
          path: '/videoCaseManages',
          name: 'videoCaseManages:videoCaseIndex',
          component: RouteView,
          meta: { title: '案例内容统计', icon: 'video-camera' },
          redirect: '/videoCaseManages',
          children: [
            //视频案例管理
            {
              path: '/videoCaseManages',
              name: 'videoCaseManages:videoCaseIndex',
              component: () => import('@/pages/app/videoCaseManages/index'),
              meta: { title: '视频案例管理' },
            },
            //视频案例管理详情
            {
              path: '/videoCaseManages/detail',
              name: 'videoCaseManages:videoCaseDetail',
              component: () => import('@/pages/app/videoCaseManages/detail'),
              hidden: true,
              meta: { title: '视频案例管理详情' },
            },
          ],
        },
        // 推广管理
        { ...deliverManages },
        //素材管理
        { ...materialManage },
        //巨量广告管理
        { ...JLAdvertManage },
        // 投放效果报表
        { ...releaseEffectReport },
      ],
    },
    // 话术管理
    {
      path: 'discourse',
      name: 'discourse',
      component: RouteView,
      meta: { title: '话术管理', icon: 'notification' },
      children: [
        {
          path: 'record',
          name: 'discourse:record',
          component: () => import('@/pages/app/discourse/discourseRecord.vue'),
          meta: { title: '话术录入' },
        },
      ],
    },
    // 剪辑管理
    {
      path: 'cloudClip',
      name: 'console',
      component: RouteView,
      meta: { title: '剪辑管理', icon: 'laptop' },
      children: [
        {
          path: 'liveTableList',
          name: 'cloudClip:liveTableList',
          component: () => import('@/pages/app/cloudClip/liveTableList'),
          meta: { title: '直播场次列表' },
        },
        {
          path: 'cliptTableList',
          name: 'cloudClip:cliptTableList',
          component: () => import('@/pages/app/cloudClip/cliptTableList'),
          meta: { title: '直播素材库' },
        },
        {
          path: 'materialDetails',
          name: 'cloudClip:materialDetails',
          component: () => import('@/pages/app/cloudClip/materialDetails'),
          meta: { title: '素材标签管理' },
          hidden: true,
        },
      ],
    },
    // 投放助手
    {
      path: 'regulation',
      name: 'regulation',
      component: RouteView,
      meta: { title: '投放助手', icon: 'control' },
      children: [
        {
          path: 'author',
          name: 'regulation:author',
          component: () => import('@/pages/app/regulation/author.vue'),
          meta: { title: '监控主播列表' },
        },
        {
          path: 'video',
          name: 'regulation:video',
          component: () => import('@/pages/app/regulation/video.vue'),
          meta: { title: '监控视频列表' },
        },
        {
          path: 'add',
          name: 'regulation:add',
          component: () => import('@/pages/app/regulation/addRegulation.vue'),
          meta: { title: '添加监控主播页', noPadding: true },
          hidden: true,
        },
      ],
    },
    // 微控群信息管理
    {
      path: 'wechatMessage',
      name: 'wechatMessage',
      component: RouteView,
      meta: { title: '微控群信息管理', icon: 'wechat' },
      children: [
        {
          path: 'list',
          name: 'wechatMessage:list',
          component: () => import('@/pages/app/wechatMessage/tagList.vue'),
          meta: { title: '标签列表页' },
        },
        {
          path: 'group',
          name: 'wechatMessage:group',
          component: () => import('@/pages/app/wechatMessage/groupList.vue'),
          meta: { title: '群广播列表' },
        },
        {
          path: 'create',
          name: 'wechatMessage:create',
          component: () => import('@/pages/app/wechatMessage/create.vue'),
          meta: { title: '创建群广播列表', noPadding: true },
        },
        {
          path: 'send',
          name: 'wechatMessage:send',
          component: () => import('@/pages/app/wechatMessage/sendMessage.vue'),
          meta: { title: '发送广播', noPadding: true },
          hidden: true,
        },
      ],
    },
    // 取数工单
    // 内容生产
    {
      path: 'contentProduction',
      name: 'contentProduction',
      component: RouteView,
      meta: { title: '内容生产', icon: 'book' },
      children: [
        {
          path: 'WorkOrderManagementList',
          name: 'WorkOrderManagementList',
          component: () => import('@/pages/app/contentProduction/WorkOrderManagementList'),
          meta: { title: '工单管理' },
        },
        {
          path: 'WorkOrderManagementPage',
          name: 'WorkOrderManagementPage',
          component: () => import('@/pages/app/contentProduction/WorkOrderManagementPage'),
          meta: { title: '工单管理' },
          hidden: true,
        },
        {
          path: 'WorkOrderManagementDetail',
          name: 'WorkOrderManagementDetail',
          component: () => import('@/pages/app/contentProduction/WorkOrderManagementDetail'),
          meta: { title: '工单管理详情' },
          hidden: true,
        },
        {
          path: 'TaskManagementList',
          name: 'TaskManagementList',
          component: () => import('@/pages/app/contentProduction/TaskManagementList'),
          meta: { title: '任务管理' },
        },
        {
          path: 'TaskManagementDetail',
          name: 'TaskManagementDetail',
          component: () => import('@/pages/app/contentProduction/TaskManagementDetail'),
          meta: { title: '任务管理详情' },
          hidden: true,
        },
        {
          path: '/progressOfTheStatisticsList',
          name: 'progressOfTheStatisticsList',
          component: () => import('@/pages/app/contentProduction/progressOfTheStatisticsList'),
          meta: { title: '进度统计' },
        },
        {
          path: '/templateAnalysisList',
          name: 'templateAnalysisList',
          component: () => import('@/pages/app/contentProduction/templateAnalysisList'),
          meta: { title: '模板分析' },
        },
        {
          path: 'usageDetail',
          name: 'templateAnalysis:usageDetail',
          component: () => import('@/pages/app/templateAnalysis/usageDetail'),
          meta: { title: '使用详情' },
          hidden: true,
        },
      ],
    },

    //标签管理
    {
      path: 'tagManage',
      name: 'tagManage',
      component: RouteView,
      meta: { title: '标签管理', icon: 'flag' },
      children: [
        {
          path: 'tagList',
          name: 'tagManage:tagList',
          component: () => import('@/pages/app/tagManage/tagList'),
          meta: { title: '标签列表' },
        },
        {
          path: 'tagEdit',
          name: 'tagManage:tagEdit',
          component: () => import('@/pages/app/tagManage/tagEdit'),
          meta: { title: '编辑标签' },
          hidden: true,
        },
      ],
    },
    // SCRM
    {
      path: 'SCRM',
      name: 'SCRM',
      component: RouteView,
      meta: { title: 'SCRM', icon: 'book' },
      children: [
        {
          path: 'messagePromptList',
          name: 'messagePromptList',
          component: () => import('@/pages/app/SCRM/messagePromptList'),
          meta: { title: '消息提示语' },
        },
      ],
    },
  ],
};
